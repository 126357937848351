:root {
  --blue: rgb(66, 133, 247);
  --purple: rgb(170, 143, 248);
  --teal: rgb(151, 225, 237);
}

section {
  max-width: 1100px;
  margin: 0 auto;
}

.min-height {
  min-height: 100vh;
  padding-bottom: 10px;
  padding-top: 10px;
}

body {
  background-color: white !important;
}

.bg--dark {
  background-color: black !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  p {
    color: white;
  }

  .Navbar p {
    color: black;
  }

  footer {
    margin-top: 20px;

    span {
      color: black;
    }
  }

  footer p {
    color: black;
  }

  header p {
    color: var(--primary) !important;
  }
}

footer {
  a {
    color: var(--primary) !important;
  }
}

.bg--primary {
  background-color: var(--primary);

  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  p {
    color: white;
  }

  .MuiSlider-thumb {
    background-color: black;

    span {
      color: black;
    }
  }
}

.MuiSlider-valueLabel > span > span {
  color: black !important;
}

@media (max-width: 600px) {
  .is-large-image {
    width: 100% !important;
    object-fit: contain !important;
  }
  .is-small-image {
    max-height: 200px;
  }
  .is-height-constained {
    height: 200px;
  }
  section {
    padding: 0 15px;
  }
  .grid {
    grid-template-columns: 1fr !important;
  }
  .grid-2 {
    .MuiAvatar-root {
      width: 80px !important;
      height: 80px !important;
      justify-self: center !important;
    }
  }
  html {
    font-size: 12px;
  }
}

:root {
  --primary: #5cbc63;
  --gray: #a7a7a7;
  --lightgray: #fafafa;
}

.text--primary {
  color: var(--primary) !important;
}

.text--gray {
  color: var(--gray) !important;
}

.text--secondary {
  color: black !important;
}

.text--helper {
  color: rgba(0, 0, 0, 0.87) !important;
}

.underline {
  text-decoration: underline;
}

.cursor {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@font-face {
  font-family: "CeraPro-Regular";
  src: url("./utils/fonts/cerapro-regular.otf");
}

@font-face {
  font-family: "CeraPro-Bold";
  src: url("./utils/fonts/cerapro-bold.otf");
}

.wall-of-text {
  p {
    font-size: 1.2rem;
  }
}

// large paragraph font-size
.body-2 {
  font-size: 1.5rem !important;
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
