.Navbar {
  background-color: transparent !important;

  .grow {
    flex-grow: 1;
  }

  a {
    text-decoration: none;
    color: black;
    margin: 0 20px;

    &.active {
      color: var(--primary);

      p {
        font-weight: bold;
      }
    }
  }
}
